import useSellerTheme from 'src/hooks/useSellerTheme';
import styled from 'styled-components';
import PageWrapper from '../wrappers';

export const Header = styled.div.attrs((props) => {
  const { isLoading } = useSellerTheme();

  return {
    ...props,
    $boxShadowColor: isLoading ? props.theme.colors.loading : props.theme.colors.main
  };
})`
  border-radius: 5px;
  padding: 5px 15px;
  background-color: ${(p) => p.theme.colors.bg};
  width: 400px;
  margin: 10px auto;
  max-width: 400px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25), 0px -3px 0px ${(p) => p.$boxShadowColor};

  @media screen and (max-width: 500px) {
    width: 100%;
    margin: 10px 0;
  }
`;

export const Container = styled.div`
  display: flex;
  width: 100%;
  position: relative;
  justify-content: center;
`;

export const Elevated = styled.div`
  z-index: 200;
  position: absolute;
  width: fit-content;
  margin: 0 auto;
  padding: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  bottom: -1rem;
`;

export const FixedTracking = styled.div`
  max-width: 400px;
  width: 100%;
  margin: 10px auto;
`;

export const BackgroundedPageWrapper = styled(PageWrapper).attrs((props) => {
  const { isLoading } = useSellerTheme();
  return { ...props, $isLoading: isLoading, 'data-cy': 'background-page-wrapper' };
})`
  background-color: ${(p) => (p.$isLoading ? '#c9c9c9' : p.theme.colors.main)};
  min-height: 100vh;
  display: flex;
  justify-content: center;
  padding: 0;
  @media screen and (max-width: 500px) {
    padding: 0 10px;
  }
`;
