import styled from 'styled-components';

export const Line = styled.span`
  width: 100%;
`;

export const Container = styled.div`
  padding: 0.2rem 0;
`;

export const Hello = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 2rem;
  color: ${(p) => p.theme.colors.bgContrast};
  justify-content: flex-start;
  align-items: flex-start;
`;

export const WrapFooterInfoHello = styled.div`
  display: flex;
  align-items: center;
  background-color: ${(p) => p.theme.colors.gray[550]};
  width: calc(100% + 30px);
  padding: 1rem 5px 15px 15px;
  margin: 0 -15px -15px -15px;
  border-radius: 0 0 5px 5px;
  color: black;

  h5 {
    font-size: 1.2rem;
    font-weight: 600;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    white-space: normal;
    overflow-wrap: break-word;
    word-break: break-word;

    span {
      font-size: 1.2rem;
      font-weight: 500;
      color: ${(p) => p.theme.colors.bgContrast};
      margin-right: 0.1rem;
      white-space: normal;
      display: inline-block;
    }
  }
`;

export const MainColorText = styled.b`
  color: ${(p) => p.theme.colors.mainContrastAgainstBg};
  font-weight: 600;
`;

export const TrackingText = styled.b`
  font-size: 1.4rem;
  font-weight: 600;
`;

export const CarrierText = styled.b`
  font-size: 1.4rem;
  font-weight: 600;
`;

export const LastUpdatedAt = styled.div`
  display: flex;
  font-size: 1.4rem;
  color: ${(p) => p.theme.colors.bgContrast};
  padding: 0 0px;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 0.3rem;
`;

export const PickupAt = styled.div`
  font-size: 1.4rem;
  color: ${(p) => p.theme.colors.bgContrast};
  padding: 0 0px;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 1rem;
`;

export const ExtraFields = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  ${TrackingText} {
    margin-right: 1rem;
  }
`;
